<template>
  <b-container class="mt-4 mb-5" fluid>
    <div class="d-flex justify-content-end">
      <b-button variant="secondary" class="mb-3" :to="{name: 'permissions.create'}" v-if="checkPermission('Create Role - Roles and Permissions')">+ Add Role</b-button>
    </div>
    <b-card class="justify-content-center bs-br">
      <b-table hover :items="Roles" :fields="Fields" :busy="loadRoles" responsive striped tbody-tr-class="transition-tr">
        <template #table-busy>
          <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="row">
            <b-button @click="getPermissionRole(row.item.name)" v-if="checkPermission('Assing Permissions To Role - Roles and Permissions')" size="sm" variant="primary">
                Permissions assigned
            </b-button> <b-button size="sm" v-if="(row.item.name != 'Member' && row.item.name != 'Administrator') && checkPermission('Edit Role - Roles and Permissions')" variant="secondary" @click="$router.push({name: 'permissions.edit', params: {id: row.item.id}})">
                Edit
            </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal v-model="permissionRoleModal" title="Role permissions" centered>
      <template #modal-header="{ close }">
          <h5>Role Permissions</h5>
          <b-button size="sm" variant="default" @click="close()">
              <vue-fontawesome icon="times"></vue-fontawesome>
          </b-button>
      </template>
      <b-container>
        <b-row>
          <b-col>
            <small>Modules</small>
            <b-card no-body class="mt-2 p-2 card-permissions" v-for="(module, key) in Modules" :key="key">
                <b-card-header role="tab" v-b-toggle="'accordion-'+key" header-class="header-actions-user">
                    <h6 class="m-0">{{module.name}}</h6>
                    <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                    <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                </b-card-header>
                <b-collapse :id="'accordion-'+key" accordion="my-accordion" role="tabpanel">
                    <b-form-group>
                        <b-form-checkbox-group
                            v-model="permissionsRole"
                            :options="module.permissions"
                            name="flavour-2a"
                            stacked
                        ></b-form-checkbox-group>
                    </b-form-group>
                </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ close }">
          <b-overlay
              :show="loadPermissions"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
              >
              <b-button size="sm" variant="primary" @click="savePermissionsToRole">
                  Save permissions
              </b-button>
          </b-overlay>

          <b-button size="sm" variant="secondary" @click="close()">
              Cancel
          </b-button>
      </template>
    </b-modal>
    <b-overlay
        :show="loadPermissions"
        opacity="0.5"
        no-wrap
        variant="dark"
    >
    </b-overlay>
  </b-container>
</template>
<script>
export default {
    name: 'permissions_index',

    data() {
        return {
            Roles: [],
            allPermissions: [],
            Fields: [
                { key: 'name', label: 'Name' },
                { key: 'actions', label: '' }
            ],
            permissionRoleModal: false,
            permissionsRole: [],
            Modules: [],
            loadRoles: false,
            loadPermissions: false,
            RoleSelected: null,
        };
    },

    mounted() {
        this.getRoles()
    },

    methods: {
        getRoles(){
            this.loadRoles = true;
            this.$http.get('role', { headers: {"Authorization": "Bearer "+this.$session.get('jwt') } }).then((response) => {
                if (response.status == 200) {
                    this.Roles = response.body.data.roles;
                    this.allPermissions = response.body.data.permissions;
                    this.Modules = this.getModules(response.body.data.permissions);
                    this.loadRoles = false;
                }
            }, (error) => {
                if (error.status == 500) {
                    this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
                }
                this.loadRoles = false;
            });
        },
        getModulePermissions(module, permissions){
            const listPermissions = [];
            permissions.forEach((permission) => {
                if (module == permission.split('-')[1].trim()) {
                    listPermissions.push({
                        value: permission,
                        text: permission.split('-')[0].trim()
                    });
                }
            });
            return listPermissions;
        },
        getPermissionRole(role){
            this.loadPermissions = true;
            this.$http.get(`role/${role}`, { headers: {"Authorization": "Bearer "+this.$session.get('jwt') } }).then((response) => {
                if (response.status == 200) {
                    this.RoleSelected = role;
                    this.permissionRoleModal = true;
                    this.permissionsRole = response.body.data;
                    this.loadPermissions = false;
                }
            }, (error) => {
                if (error.status == 500) {
                    this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
                }
                this.loadPermissions = false;
            })
        },
        savePermissionsToRole(){
            this.loadPermissions = true;
            this.$http.put(`role/${this.RoleSelected}/updatePermissions`, this.permissionsRole, { headers: {"Authorization": "Bearer "+this.$session.get('jwt') } }).then((response) => {
                if (response.status == 200) {
                    this.permissionRoleModal = false;
                    this.$notify({ group: 'notifications_app', type: 'success', text: response.body.message});
                }
                this.loadPermissions = false;
            }, (error) => {
                if (error.status == 500) {
                    this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
                }
                this.loadPermissions = false;
            })
        }
    },
};
</script>
